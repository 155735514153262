/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";



/*Fonts*/
@font-face {   
  font-family: 'Varela';    //This is what we are going to call
  src: url('./assets/fonts/SpaceGrotesk-Regular.ttf');
   font-weight: bolder bold !important;
}

@font-face {   
  font-family: 'Lato';    //This is what we are going to call
  src: url('./assets/fonts/Lato-Regular.ttf');
   font-weight: 100 900 !important;
}

@font-face {   
  font-family: 'Capsuula';    //This is what we are going to call
  src: url('./assets/fonts/Capsuula.ttf');
  font-weight: bolder bold !important;

}

@font-face {   
  font-family: 'OpenSans';    //This is what we are going to call
  src: url('./assets/fonts/OpenSans-CondBold.ttf');

}

/*@font-face {   
  font-family: 'OpenSans2';    //This is what we are going to call
  src: url('./assets/fonts/OpenSans-Cond.ttf');



}*/

@font-face {
  font-family: 'Roboto'; //This is what we are going to call
  src: url('./assets/fonts/roboto/Roboto-Black.ttf');
  font-weight: 900;
}

@font-face {
  font-family: 'Roboto'; //This is what we are going to call
  src: url('./assets/fonts/roboto/Roboto-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: 'Roboto'; //This is what we are going to call
  src: url('./assets/fonts/roboto/Roboto-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: 'Roboto'; //This is what we are going to call
  src: url('./assets/fonts/roboto/Roboto-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'Roboto'; //This is what we are going to call
  src: url('./assets/fonts/roboto/Roboto-Light.ttf');
  font-weight: 300;
}
@font-face {
  font-family: 'Roboto'; //This is what we are going to call
  src: url('./assets/fonts/roboto/Roboto-Thin.ttf');
  font-weight: 100;
}

.bold{
    font-weight: 500;
}

.bolder{
    font-weight: 700;
}

.boldest{
    font-weight:900
}

.light{
    font-weight:300;
}

.thin{
    font-weight: 100
}



.alert-tappable.alert-radio {
    height: auto;
    contain: content;
}

.alert-radio-label.sc-ion-alert-md,.alert-radio-label.sc-ion-alert-ios {
    white-space: normal;
}



/* Attempt to fix cuisine select-option alert blurriness*/
.custom-alert .alert-checkbox-group{

    

    .alert-checkbox-label{
       
    }

}


/*ion-list-header{
    font-size: 18px;
    font-weight: normal;
    color: gray;
    p{
        margin-top: 0px;
        margin-bottom: 0px;
    }
   
}*/

ion-card{
    ion-card-header{

        ion-card-title{
            font-size: 16px;    
        } 
    }
}


/* Horizontal Line*/
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}


p{
    color: gray;
}


/*ion-list{
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}*/





/***Sheet Modals***/
ion-modal.sheet-modal {
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal.sheet-modal ion-content::part(scroll) {
  overflow-y: var(--overflow);
  overscroll-behavior-y: contain;

}

ion-modal.sheet-modal::part(backdrop) {
  background: rgba(209, 213, 219);
  opacity: 1;
}

ion-modal.sheet-modal ion-toolbar, ion-modal ion-toolbar.card-modal-toolbar, ion-modal ion-toolbar.sheet-modal-toolbar {
  --background: var(--ion-color-primary);/*rgb(14 116 144);*/
  --color: white;
  ion-button{
      --color: white;
  }
}

.swiper-pagination {
  padding-top: 5px;
  border: 1px black solid;

}


.ngx-charts-outer{
  padding-bottom: 200px;
  margin-bottom: 200px;
}


.feature-popover{
  
  --backdrop-opacity: 0.6;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  --color: white;
}

/***Smaller accordion***/
ion-accordion.informative{
  ion-icon{
    font-size: 10px;
    color: gray;
  }
}

